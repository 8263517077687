<template>
  <section>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-card-body>
            <b-card-text>
              <b-row>
                <b-col lg="6" cols="12">
                  <template>
                    <h5 class="text-capitalize">Current Package</h5>

                    <div v-if="companyData?.package">
                      <b-card-text> <strong>Title:</strong> {{ companyData?.package?.title }}</b-card-text>
                      <b-card-text> <strong>Billing Cycle:</strong> 
                        <template v-if="companyData?.package?.billing_cycle === 'monthly'">
                          Monthly
                        </template>
                        <template v-else-if="companyData?.package?.billing_cycle === 'yearly'">
                          Yearly  
                        </template>
                        <template v-else>
                          Life Time  
                        </template>
                      </b-card-text>
                      <b-card-text> <strong>Max User:</strong> {{ companyData?.package?.max_user }} users</b-card-text>
                      <b-card-text> <strong>Subscription Expire Date:</strong> {{ subscriptionExpireDate ? formatSubscriptionExpiredDateTime(subscriptionExpireDate) : 'N/A'  }}</b-card-text>
                      
                      <b-button
                        v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                        variant="flat-warning"
                        class="border"
                        @click="showModal = true"
                      >
                      Cancel Subscription
                      </b-button>
                      <router-link to="/pricing">
                        <b-button
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-success"
                          class="border mx-1"
                        >
                          Update Package
                        </b-button>
                      </router-link>
                    </div>
                  </template>
                </b-col>
                <b-col lg="6" cols="12">
                  <template>
                    <h5 class="text-capitalize">Days</h5>

                    <div>
                      <label
                        >{{ value }} days remaining of your next payment</label
                      >
                      <b-progress :max="max">
                        <b-progress-bar :value="value">
                          <strong>{{ value }} / {{ max }}</strong>
                        </b-progress-bar>
                      </b-progress>
                    </div>
                  </template>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <div>
        <!-- table -->
        <vue-good-table
          styleClass="vgt-table table-custom-style striped"
          :line-numbers="false"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :rows="rows"
          :columns="columns"
          :sort-options="{
            enabled: false,
            multipleColumns: true,
            initialSortBy: [{ field: 'created_at', type: 'desc' }],
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- payment date -->
            <template v-if="props?.column?.field === 'format_created_at'">
              <div v-if="props.row.created_at">
                {{ formatDateTime(props.row.created_at) }}
              </div>
            </template>

            <!-- subscription -->
            <template v-if="props?.column?.field === 'format_invoice_id'">
              <a :href="props.row.invoice_url" rel="noopener noreferrer">
                <span>
                  <b-badge pill variant="light-primary">
                    Download <feather-icon icon="ArrowDownIcon" size="18" />
                  </b-badge>
                </span>
              </a>
            </template>

            <template v-if="props?.column?.field === 'format_amount'">
              <div v-if="props.row.amount_paid">
                <div>
                  {{ props?.row?.currency?.toUpperCase() }}
                  {{ props.row.amount_paid }}
                </div>
              </div>
            </template>

            <!-- next payment date -->
            <template
              v-if="props?.column?.field === 'format_next_payment_date'"
            >
              <div v-if="props.row.next_payment_date">
                {{ formatDateTime(props.row.next_payment_date) }}
              </div>
            </template>

            <template v-if="props?.column?.field === 'format_status'">
              <span v-if="props.row.payment_status == 'paid'">
                <b-badge pill variant="light-success">
                  {{ capitalizeFirstLetter(props.row.payment_status) }}
                </b-badge>
              </span>
              <span v-else>
                <b-badge pill variant="light-danger">
                  {{ capitalizeFirstLetter(props.row.payment_status) }}
                </b-badge>
              </span>
            </template>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10', '25', '50', '100', '500']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>

    <b-modal v-model="showModal" title="Cancel Subscription" hide-footer>
      <p class="my-2">Do you want to cancel now or at the end of your package?</p>
      
      <div class="d-flex justify-content-between">
        <!-- Cancel Now Button with Tooltip -->
        <b-button
          variant="danger"
          @click="pauseSubscription"
          v-b-tooltip.hover
          title="If you cancel now, your subscription will end immediately. You won't be able to use the service after 24 hours."
        >
          Cancel Now
        </b-button>

        <!-- Cancel at End of Package Button with Tooltip -->
        <b-button
          variant="warning"
          @click="cancelAtEndSubscription"
          v-b-tooltip.hover
          title="If you cancel at the end of the package, you can continue using your subscription until it expires. After that, access will be revoked."
        >
          Cancel at End of Package
        </b-button>
      </div>

      
      <b-button class="mt-3" variant="secondary" block @click="showModal = false">
        Close
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BRow,
  BCol,
  VBTooltip,
  BProgress,
  BProgressBar,
  BCardBody,
  BCardText,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import UserAssignPolicyForm from "@/views/admin/payroll-management/UserAssignPolicyForm.vue";

export default {
  name: "PaymentHistory",
  components: {
    UserAssignPolicyForm,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BRow,
    BCol,
    VBTooltip,
    BProgress,
    BProgressBar,
    BCardBody,
    BCardText,

  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {

      modelType: "",
      name: "",
      
      showModal: false,
      companyId: "",

      value: 0,
      max: 0,
      companyData: "",
      subscriptionExpireDate: "",
      pageLength: 10,
      columns: [
        {
          label: "Payment Date",
          field: "format_created_at",
          sortable: false,
        },
        {
          label: "Invoice",
          field: "format_invoice_id",
          sortable: false,
        },
        {
          label: "Amount",
          field: "format_amount",
          sortable: false,
        },
        {
          label: "Status",
          field: "format_status",
          sortable: false,
        },
        {
          label: "Next Payment Date",
          field: "format_next_payment_date",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isSalaryPolicyFormSubmitLoading: false,
      isUserPolicyAssignFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
      authUser: "userModule/getUser",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
    getPaymentMethod() {
      return this.$store.getters["paymentMethodModule/getPaymentMethod"];
    },
  },

  async created() {
    this.companyId = this.authUser?.company_id ?? "";
    try {
      const response = await this.getCompanyInfo(this.companyId);
      this.companyData = response?.data?.data;

      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    daysInProgressBar(data) {
      if (data) {
          const createdAt = new Date(data.created_at);
          const nextPaymentDate = new Date(data.next_payment_date);
          const today = new Date();

          const maxDuration = Math.floor((nextPaymentDate - createdAt) / (1000 * 60 * 60 * 24)); 

          const elapsedDuration = Math.floor((today - createdAt) / (1000 * 60 * 60 * 24)); 

          this.max = maxDuration; 
          this.value = elapsedDuration; 
        }
    },
    capitalizeFirstLetter(string) {
      if (!string) return "";
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    
    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },

    formatSubscriptionExpiredDateTime(value) {
      if (value) {
        return this.$moment(value).subtract(1, 'days').format("MMM Do YYYY");
      }
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    async getPaymentHistory(params) {
      return await this.$api.get("api/subscription-history", {
        params: {
          company_id: params.company_id,
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
          payment_method: params.payment_method,
        },
      });
    },

    async getCompanyInfo(companyId) {
      return await this.$api.get("api/current-subscription-package", {
        params: {
          company_id: companyId,
        },
      });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    async loadItems() {
      try {
        const paymentHistories = await this.getPaymentHistory({
          company_id: this.companyId,
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
          q: this.searchTerm,
          payment_method: this.getPaymentMethod,
        });

        const data = paymentHistories?.data?.data;
        const meta = paymentHistories?.data?.meta;
        this.subscriptionExpireDate = meta?.subscriptionExpired?.next_payment_date

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;

        if (this.rows.length > 0) {
          this.daysInProgressBar(this.rows[0]);
        }
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async getPauseSubscription(params) {
      return await this.$api.get("api/pause-subscription", {
        params: {
          company_id: params.company_id,
        },
      });
    },

    async pauseSubscription() {
      this.$swal({
        title: "Warning!",
        html: `This action is irreversible! <br> Are you sure, You want to cancel your subscription?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await this.getPauseSubscription({
                  company_id: this.authUser?.company_id,
                });

              if (response.data.status == "success") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: response.data.message,
                },
              });

            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: response.data.message,
                },
              });
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    
    async postCancelAtEndSubscription(params) {
      return await this.$api.post("api/pause-subscription-on-expire-date", {
        params: {
          company_id: params.company_id,
        },
      });
    },

    async cancelAtEndSubscription() {
      this.$swal({
        title: "Warning!",
        html: `This action is irreversible! <br> Are you sure, You want to cancel your subscription?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await this.postCancelAtEndSubscription({
                  company_id: this.authUser?.company_id,
                  subscription_status: 3
                });

              if (response.data.status == "success") {
                this.showModal = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: response.data.message,
                  },
                });

            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: response.data.message,
                },
              });
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.required-label label::after {
  content: " *";
  color: red;
}
</style>